import React, { useState, useEffect } from 'react';
import InfiniteScroll from "react-infinite-scroll-component";


function Hamsfeeder({info}) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);

  useEffect(() => {
    // Fetch initial data
    fetchData();

    // Cleanup function to cancel any ongoing requests
    return () => {
      // Cancel any ongoing fetch requests here (if using fetch API)
    };
  }, []);

  const fetchData = () => {
    setLoading(true);

    // Send a request to the backend API to fetch data
    fetch('/getPassages', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        user_id: info.email,
        page: page,
      }),
    })
      .then(response => response.json())
      .then(responseData => {
        console.log(responseData);
        setData(prevData => [...prevData, ...responseData]);
        setLoading(false);
        setPage(prevPage => prevPage + 1);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        setLoading(false);
      });
  };

  function addToFavorite(pid){
    console.log(pid);
    fetch('/add_favorite', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          user_id: info.email,
          pid: pid,
        }),
      })
  }

  return (
    <div className="row" id="feed">
        <h5>Short passages from great books by awesome authors. Happy Reading!</h5>


    <InfiniteScroll
            dataLength={data.length}
            next={fetchData}
            hasMore={true}
            loader={<p>Loading...</p>}
            endMessage={<p>No more data to load.</p>}
        >
            {data.map((passage, index) => (
                <>
                <div key={passage.pid} className="row">
                    <div className="col-12">
                        <div className="card border-1 mb-3">
                        <div className="row g-0">
                        <div className='col-12'><br /></div>

                        <div className="col-md-2 text-center">
                            <img src={passage.img} className="rounded-circle center-block" alt="Book Picture" style={{"width": "80px", "height": "80px"}} />
                        </div>

                        <div className="col-md-10">
                        <div className="card-body">
                            <h7 className="card-title"><strong>{passage.works} - <em>{passage.author}</em></strong></h7>
                            <p className="card-text">{passage.passage}</p>
                            {/*<p className="card-text"><em>- {passage.author}, {passage.works}</em></p>*/}
                            <div className="d-flex justify-content-end">
                                <button type="button" className="btn btn-outline-primary btn-sm" onClick={() => {addToFavorite(passage.pid);}}>
                                    <i className="bi bi-hand-thumbs-up-fill">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-hand-thumbs-up-fill" viewBox="0 0 16 16">
                                      <path d="M6.956 1.745C7.021.81 7.908.087 8.864.325l.261.066c.463.116.874.456 1.012.965.22.816.533 2.511.062 4.51a9.84 9.84 0 0 1 .443-.051c.713-.065 1.669-.072 2.516.21.518.173.994.681 1.2 1.273.184.532.16 1.162-.234 1.733.058.119.103.242.138.363.077.27.113.567.113.856 0 .289-.036.586-.113.856-.039.135-.09.273-.16.404.169.387.107.819-.003 1.148a3.163 3.163 0 0 1-.488.901c.054.152.076.312.076.465 0 .305-.089.625-.253.912C13.1 15.522 12.437 16 11.5 16H8c-.605 0-1.07-.081-1.466-.218a4.82 4.82 0 0 1-.97-.484l-.048-.03c-.504-.307-.999-.609-2.068-.722C2.682 14.464 2 13.846 2 13V9c0-.85.685-1.432 1.357-1.615.849-.232 1.574-.787 2.132-1.41.56-.627.914-1.28 1.039-1.639.199-.575.356-1.539.428-2.59z"/>
                                    </svg>
                                    </i>
                                </button>
                                {/*
                                <button type="button" className="btn btn-outline-primary btn-sm" onClick={() => {addToFavorite(userId, passage.pid);}}>
                                    Add to Favorite
                                </button>
            */}
                            </div>
                            </div>
                        </div>
                        </div>
                        </div>
                    </div>
                </div>
                </>
                    ))}
      </InfiniteScroll>

    </div>
  );
}

export default Hamsfeeder;
import {useState, useEffect} from 'react';
import searching from './searching.png';
import shrugging from './shrugging.png';
import goodJob from './goodJob.png';

function Hamstory({info}){

    const [hasStories, setHasStories] = useState(false);
    const [stories, setStories] = useState([]);

    useEffect(() => {
        fetch('/get_hamstory', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({user_id: info.email}),
        })
        .then((response) => response.json())
        .then((data) => {
            console.log(data);
            setHasStories(true);
            setStories(data);
        });
    }, []);

    return (
        <div className="row vh100 d-flex justify-content-center align-items-center">
            { hasStories? (
                (stories.length === 0? (
                    <div className="row vh-100 d-flex justify-content-center align-items-center">
        <div className="col-6 text-center">
          <h1><strong>No record found</strong></h1>
          <h1><strong>Start writing?</strong></h1>
        </div>
        <div className="col-4 align-items-center justify-content-center">
            <div className="col-sm-11 col-md-9 col-bg-7 d-flex align-items-center justify-content-center">
              <img src={shrugging} alt="no record" className="img-fluid" />
            </div>
          </div>
      </div>
                ) : (
        <div className="col-12">
          <h1>Your Hamstories</h1>
          <p><i>Hamswriter is still practicing its drawing skills. It may take up to one day for it to illustrate your latest writings.</i></p>

            { stories.map((item) => (
<>
<br />
 	  <div class="card">
  	  	<div class="row"><br /></div>
            <div class="row ">
                <div class="col-1"></div>
            <div class="col-md-4 col-10">
                <br />
                <img src={item.link} alt="" width="300px" height="300px" class="map" />
            </div>

      <div class="col-md-6 col-10">
      	<div class="card-title" style={{"padding-left": "15px"}}>
      		<h5>{item.date}</h5>
      	</div>
        <div class="card-block" style={{"padding-left": "15px"}}>
            <p>{item.writing_latest}</p>
            <p><em>{item.writing_first}</em></p>
        </div>
      </div>
      <div class="col-1"></div>


    </div>
    <div class="row"><br /></div>
</div>

</>

               
            
            
            ))
            }        
{/*            </tbody>
        </table> */}
        <div className="row vh-100 d-flex justify-content-center align-items-center">
        <div className="col-6 text-center">
          <h1><strong>Good Job!</strong></h1>
          <h1><strong>Keep it up!</strong></h1>
        </div>
        <div className="col-4 align-items-center justify-content-center">
            <div className="col-sm-11 col-md-9 col-bg-7 d-flex align-items-center justify-content-center">
              <img src={goodJob} alt="Good Job!" className="img-fluid" />
            </div>
          </div>
      </div>

        </div>
            ))
            ): (<>
                <div className="col-6 text-center">
                <h1><strong>Searching for your Hamstories</strong></h1>
              </div>
              <div className="col-4 align-items-center justify-content-center">
                  <div className="col-sm-11 col-md-9 col-bg-7 d-flex align-items-center justify-content-center">
                    <img src={searching} alt="created with ai" className="img-fluid" />
                  </div>
                </div>
                </>
            )}
    </div>
    );
}

export default Hamstory;
import {useState} from 'react';
import { useNavigate } from "react-router-dom";


function Registration() {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [regStatus, setRegStatus] = useState('');
    const [inviteCode, setInviteCode] = useState('');
    const navigate = useNavigate();


    const handleSubmit = async (event) => {
        event.preventDefault();
        if (password !== confirmPassword) {
          setRegStatus('Passwords do not match!');
          return;
        }
    
        if (password.length < 8) {
          setRegStatus('Password should be at least 8 characters long!');
          return;
        }
        const response = await fetch('/api/register', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({ username, password, inviteCode })
          });
      
          const data = await response.json();
          if (data.success) {
            setRegStatus('Registration successful! Please login now.');
          } else {
            setRegStatus('Registration failed!');
          }
    };

    return (
        <form onSubmit={handleSubmit}>
            <div className="col-12">
                <p>{regStatus}</p>
            </div>
            <div className="col-12">
            <label for="email" className="form-label">Email <span className="text-danger">*</span></label>
            <input type="email" className="form-control" name="email" id="email" value={username} onChange={e => setUsername(e.target.value)} />
          </div>
          <div className="col-12">
          <label for="password" className="form-label">Password <span className="text-danger">*</span></label>
            <input type="password"  className="form-control" name="password" id="password" value={password} onChange={e => setPassword(e.target.value)} />
          </div>
          <div className="col-12">
          <label for="password" className="form-label">Confirm Password <span className="text-danger">*</span></label>
            <input type="password"  className="form-control" name="confirmPassword" id="confirmPassword" value={confirmPassword} onChange={e => setConfirmPassword(e.target.value)} />
          </div>
          <div className="col-12">
          <label for="password" className="form-label">Invite Code<span className="text-danger">*</span></label>
            <input type="password"  className="form-control" name="inviteCode" id="inviteCode" value={inviteCode} onChange={e => setInviteCode(e.target.value)} />
          </div>
          <div className="col-12">
                <div className="d-grid" style={{"margin-top": "15px"}}>
                    <button className="btn btn-lg btn-outline-success" type="submit">Create a New Account</button>
                 </div>
            </div>
        </form>
      );
    
}

export default Registration;
import {useEffect, useState} from 'react';


function Hamsvocab({info}) {
    const [words, setWords] = useState([]);
    const [noVocabulary, setNoVocabulary] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        fetch('/api/get_vocabulary', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({user_id: info.email}),
        })
        .then((response) => response.json())
        .then((data) => {
            console.log(data);
            setWords(data);
            data.length === 0 ? setNoVocabulary(true) : setNoVocabulary(false);
            setLoading(false);
        })
        .catch((error) => {
            setErrorMessage("Something went wrong. Please refresh");
            setLoading(false);
        })
    }, [])

    return (
        <>
        <div className="row">
            <p>{errorMessage}</p>
            <h3>New words to learn, based on your writings</h3>
            {loading && <div className="col-2" style={{"margin-top": "30px"}}> 
                <div className="spinner-border" role="status">
                <span className="sr-only">Searching ...</span>
                </div>
            </div>}
            {noVocabulary ? <p>You have no vocabulary suggestions yet. Please write more!</p> : ""}
            {words.length > 0 ? words.map((word)=> (
                    <div className="card col-lg-5 col-md-12 col-sm-12 col-xs-12" style={{"margin-top": "10px", "margin-left": "8px"}}>
                        <div className="card-body">
                            <h5 className="card-title">{word.word}</h5>
                            <h6 className="card-subtitle mb-2 text-muted"><em>Similar to: </em> {word.vocab}</h6>
                            <p className="card-text"><em>Example: </em>{word.example}</p>
                        </div>
                    </div>
                )
            ):""}
        </div>
        </>
    )
}

export default Hamsvocab;
import {useState, useRef} from 'react';
import listening from './listen.jpeg';
import prompts from './prompts.json';
import speaking from './speaking.jpeg';


function Hamspeaker({info}){

    const [status, setStatus] = useState('idle');
    const [audioURL, setAudioURL] = useState('');
    const [recorder, setRecorder] = useState(null);
    const [recordingTime, setRecordingTime] = useState(0);
    const [transcribedText, setTranscribedText] = useState({});
    const [showListening, setShowListening] = useState(false);
    const [topic, setTopic] = useState("");
    const audioRef = useRef();
    let intervalId;
  
    const handleStartRecording = async () => {
      setStatus('recording');
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      const mediaRecorder = new MediaRecorder(stream);
      mediaRecorder.start();
  
      // Limit the length of the recording to 5 minutes
      setTimeout(() => {
        if (status === 'recording') {
          handleStopRecording();
        }
      }, 300000);
  
      intervalId = setInterval(() => {
        setRecordingTime(prevTime => prevTime + 1);
      }, 1000);
  
      const chunks = [];
      mediaRecorder.ondataavailable = e => chunks.push(e.data);
      mediaRecorder.onstop = () => {
        clearInterval(intervalId);
        setRecordingTime(0);
        const blob = new Blob(chunks);
        console.log(blob);
        setAudioURL(URL.createObjectURL(blob));
        console.log(audioURL);
        setShowListening(true);
        setTranscribedText({});
  
        // Send a POST request along with the user ID to the backend server for processing
        const formData = new FormData();
        formData.append('audio', blob);
        formData.append('user_id', info.email);
        formData.append('topic', topic);
        fetch('/process_audio', { method: 'POST', body: formData })
        .then(response => response.json())
        .then((data) => {
          console.log('Success:', data);
          console.log(audioURL);
          setStatus('idle');
          setTranscribedText(data);
          setShowListening(false);
      });
      };
      setRecorder(mediaRecorder);
    };
  
    const handleStopRecording = () => {
      setStatus('stopped');
      recorder.stop();
    };
  
    const handlePlayRecording = () => {
      audioRef.current.play();
    };
  
    const handleDownloadRecording = () => {
      const link = document.createElement('a');
      link.href = audioURL;
      link.download = 'recording.webm';
      link.click();
    };
  
    const handleTopicChange = (event) => {
      setTopic(event.target.value);
    }

    const handlePrompt = () => {
      const randomPrompt = prompts[Math.floor(Math.random() * prompts.length)];
      setTopic(randomPrompt);
    }
  
    return (
      <div className="container">
        <div className="row">
      <div className="col-sm-12 col-md-4">
        <div className="col-12">
            <img src={speaking} alt="speaking hamster" className="img-responsive" width="300" height="300"/>
        </div>
      <div id="recorder" className="col-12">
        <h3>Record your speech</h3>
        <p>Write down your topic, hit the button to start recording, stop when you are done talking, and Hamspeaker will tell you how clear your speech sounds to your audience.</p>
        <p>Or try a prompt: &nbsp; &nbsp; <button className="btn btn-outline-success" onClick={handlePrompt} >Prompt me a topic</button></p>
        <input type="text" className="form-control form-control-lg" value={topic} onChange={handleTopicChange} placeholder="Enter your speech topic (optional)"></input>
        <br />
        <p><strong>Recording Status: {status}</strong></p>
        {status === 'recording' && <p>Recording time: {recordingTime} seconds</p>}
        <button className="btn btn-primary btn-sm" onClick={handleStartRecording} disabled={status === 'recording'}>
          Start Recording
        </button>
        <button className="btn btn-secondary btn-sm" onClick={handleStopRecording} disabled={status !== 'recording'}>
          Stop and Submit
        </button>
        </div>
        <div className="col-12">
          <br />
          {audioURL === ''? <audio controls ref={audioRef}/>:<audio controls src={audioURL} ref={audioRef} />}
          <br />
      </div>
      </div>
      <div id="transcribed" className="col-sm-12 col-md-8">
        {showListening && <div className="col-2" style={{"margin-top": "30px"}}> 
        <div className="spinner-border" role="status">
          <span className="sr-only">Listening ...</span>
        </div>
        </div>}
      
      {('content' in transcribedText) && <><h3>What I hear you say: </h3> <p><em>The score shows pronunciation clarity.</em></p></>}
      {('content' in transcribedText) && transcribedText['content'].map((d) => (
          <p>{d[0]}, <strong>{d[1]}/100</strong></p>
      ))}
  
      {('comments' in transcribedText) && <h3>Comments on your speach:</h3>}
      {('comments' in transcribedText) && <p><strong>The common filler words:</strong></p>}
      {('comments' in transcribedText) && transcribedText['comments']['list_of_filler_words'].map((d)=> (
        <p>{d}</p>
      ))}
      {('comments' in transcribedText) && <p><strong>Impact of the filler words:</strong></p>}
      {('comments' in transcribedText) && <p>{transcribedText['comments']['impact_of_filler_words']}</p>}
      {('comments' in transcribedText) && <p>{transcribedText['comments']['suggestion_for_improve_filler_words']}</p>}
      {('comments' in transcribedText) && <p><strong>Effectiveness of your content:</strong></p>}
      {('comments' in transcribedText) && <p>{transcribedText['comments']['effectiveness_of_content']}</p>}
      {('comments' in transcribedText) && <p>{transcribedText['comments']['suggested_improvement_on_content']}</p>}
      
      </div>
      </div>
    </div>
    );


}

export default Hamspeaker;
import {useState, useEffect} from 'react';
//import loading from './loading.gif';
import Select from 'react-select';
import prompts from './prompts.json';
import grading from './grading.png';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Tesseract from 'tesseract.js';
import ocr from './ocr.png';
import {example} from './variables.js';

//TODO: save writing to local storage and delete at submit

function Grader({info}){
    const [showLoadingSpinner, setShowLoadingSpinner] = useState(false);
    const [showResults, setShowResults] = useState(false);
    const [result, setResult] = useState({
      overall_score: null, 
      did_well: '',
      can_improve: '',
      sentences_to_improve: [],
      expressions_to_improve: [], 
      original_and_edited_compared: "",
      grammar_mistakes: [],
    });

    const [errorFlag, setErrorFlag] = useState(0);

    const [writingPrompt, setWritingPrompt] = useState(null);
    const [promptDisabled, setPromptDisabled] = useState(false);

    const [image, setImage] = useState(null);

    const options = [
        {label: '3rd', value: '3rd'},
        {label: '4th', value: '4th'},
        {label: '5th', value: '5th'},
        {label: '6th', value: '6th'},
        {label: '7th', value: '7th'},
        {label: '8th', value: '8th'},
        {label: '9th', value: '9th'},
        {label: '10th', value: '10th'},
        {label: '11th', value: '11th'},
        {label: '12th', value: '12th'},
      ];

    
    const initialPlaceholder = '<p><br /></p><p><br /></p><p><br /></p><p><br /></p><p><br /></p><p><br /></p><p><br /></p><p><br /></p><p><br /></p>'
    const [textInput, setTextInput] = useState((localStorage.getItem('textInput')==='null') || (localStorage.getItem('textInput')===null) || (localStorage.getItem('textInput')==='<p>null</p>')?initialPlaceholder:localStorage.getItem('textInput'));
    
    useEffect(()=>{
      const value = localStorage.getItem('textInput');
      console.log(value);

      if((value != null) & (value != initialPlaceholder)){
        setTextInput(value);
      }

    }, []);
    
    const [authorLevel, setAuthorLevel] = useState(options[0].value);
    const [evalLevel, setEvalLevel] = useState(options[0].value);
    const [submitDisabled, setSubmitDisabled] = useState(true);
    const [ocrLoading, setOcrLoading] = useState(false);
    const [ocrButtonDisabled, setOcrButtonDisabled] = useState(false);

    useEffect(()=>{
      fetch('/log_user', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          user_id: info.email
      })
    })},[]);

  
  
    function handleTextInputChange(value){
      setTextInput(value);
      if(value != initialPlaceholder){
        localStorage.setItem('textInput', value);
      }
      if((value.length > 80) & (value != initialPlaceholder)){
        setSubmitDisabled(false);
      } else {
        setSubmitDisabled(true);
      }
    }

    const handleImageUpload = (event) => {
      setImage(event.target.files[0]);
      console.log(image);
    };

    const handleGoogleOCR = () => {
      if (image) {
        setOcrLoading(true);
        setOcrButtonDisabled(true);

        const formData = new FormData();
        formData.append('image', image);
  
        fetch('/ocr', {
          method: 'POST',
          body: formData,
        })
          .then((response) => response.json())
          .then((data) => {
            if (data.result.length > 0) {
              setTextInput(textInput + '<p>' + data.result + '</p>');
              localStorage.setItem('textInput', textInput + '<p>' + data.result + '</p>');
            } else {
              setTextInput('<p>No text detected. </p>');
            }
            setOcrLoading(false);
            setOcrButtonDisabled(false);
          })
          .catch((error) => {
            console.error('Error:', error);
            setOcrLoading(false);
            setOcrButtonDisabled(false);
            // Handle the error
          });

      }
    };
  

    const handleOCR = async () => {
      if (image) {
        setOcrLoading(true);
        setOcrButtonDisabled(true);
        const worker = await Tesseract.createWorker({logger: m => console.log(m)});
        //await worker.load();
        await worker.loadLanguage('eng');
        await worker.initialize('eng');
        const {
          data: { text },
        } = await worker.recognize(image);
        console.log(text);
        setTextInput(textInput + '<p>' + text + '</p>');
        setOcrLoading(false);
        setOcrButtonDisabled(false);
        await worker.terminate();
      }
    };
  
    function handleClear(event){
      event.preventDefault();
      setResult({
        overall_score: null, 
        ideas_score: null,
        organization_score: null,
        expressions_score: null,
        grammar_score: null,
        legibility_score: null,
        did_well: '',
        can_improve: '',
        sentences_to_improve: [],
        expressions_to_improve: [], 
        grammar_mistakes:[],
        original_and_edited_compared: '',
      });
      setSubmitDisabled(true);
      setShowResults(false);
      setTextInput('');
      setErrorFlag(0);
      localStorage.setItem("textInput", null);
  
    }
  
    function handleSubmit(event){
      event.preventDefault();
      setShowLoadingSpinner(true);
      setSubmitDisabled(true);

      try {
        fetch('/get_rewrite', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              original: textInput,
              author_grade: authorLevel,
              eval_grade: authorLevel,
              user_id: info.email,
            }),
          })
         .then(response => {
            if (!response.ok) {
              throw Error(response.statusText);
            }
            return response.json()
        })
         .then(data => {
            console.log(data);
            setResult(data);
            setShowLoadingSpinner(false);
            setShowResults(true);
            setErrorFlag(0);
            setSubmitDisabled(true);
            localStorage.setItem("textInput", null);
         }).catch(e =>{
            setErrorFlag(1);
            console.log(e);
            setShowLoadingSpinner(false);
            setSubmitDisabled(false);
         });
      } catch (e) {
        setErrorFlag(1);
        console.log(e);
        setShowLoadingSpinner(false);
        setSubmitDisabled(false);
      }
  
    }

    function getPrompt(e){
      e.preventDefault();
      setPromptDisabled(true);
      setWritingPrompt(prompts[Math.floor(Math.random() * prompts.length)]);
      setPromptDisabled(false);

    }

    function handleExample(e){
      e.preventDefault();
      setTextInput(example);
    }
  
    return (
      <>
      <div className="row">
        <div className="col-12">
        <h2>Write Your Article</h2>
        <p>Ready to start writing your article? Just type it in the text area below, or upload a photo of your existing writing. We'll help you improve your writing with our AI-powered tools.</p>
        <div>
        <button className="btn btn-outline-success" onClick={handleExample}>Click to try an example!</button>
        <br />
        </div>
        <div className="col-md-12 col-bg-6">
        <div className="mb-5 input-group">
          <br />
          <input className="form-control input-sx" type="file" accept="image/*" capture="environment" onChange={handleImageUpload} />
           <button disabled={ocrButtonDisabled} className="btn btn-outline-secondary input-sx" onClick={handleGoogleOCR}>Upload</button>
           <br />
        </div>
        </div>
        {ocrLoading?<div className="col-sm-4 col-md-3 col-bg-2 d-flex"><img className="img-fluid" src={ocr} alt="Looking at your photo"/></div>:<></>}
        
            <p>Don't know what to write?  &nbsp; &nbsp; &nbsp;
               <button disabled={promptDisabled} className="btn btn-outline-success" onClick={getPrompt}>Try a Prompt</button>
            </p>
          <div>{writingPrompt == null? "":(
            <>
            <h5>{writingPrompt}</h5>
            </>
          )}
          </div>
          
          <form>
            <div className="row">
            <ReactQuill  
              name="original_writing" 
              value={textInput} onChange={handleTextInputChange}
              style={{ height: "calc(100% - 42px)",  "min-height": "200px", "font-size": "18px !important" }}
              required />
              </div>
              <div><br /></div>
              <div className="row">
              <br />
              <div className="col-12">
                <div className="col-12">
              <label for='author_level'><h3>What grade are you in?</h3><p>Please select the grade level you are in. This will help us provide you with the most relevant feedback.</p></label>
              </div>
              <div className="col-3">
              <Select  
              options={options} name='author_level' 
              defaultValue={options[0]}
              required
              onChange={(e)=>{setAuthorLevel(e.value);}}>{authorLevel}</Select>
              </div>
              </div>
              <br />
              
{/*  
              <label className="col-2" for='eval_level'><strong>Evaluation Grade Level</strong></label>
              <Select 
              className="col-3" options={options} name='eval_level' 
              required
              defaultValue={options[0]}
              onChange={(e) => {setEvalLevel(e.value);}}>{evalLevel}</Select> 
        */}
              </div>
              <br />
            <button className="btn btn-primary" disabled={submitDisabled} onClick={handleSubmit}>Submit</button>
            <button className="btn" onClick={handleClear}>Clear</button>
          </form>
        </div>
        <br />
      </div>
        {errorFlag === 1? <div className="row"><div className="col-12"><br/><h3>Something went wrong. Please retry.</h3></div></div>:<div></div>}
        {showLoadingSpinner?<div className="row"><br /><div className="col-3">
          <div>
          <img src={grading} alt='grading'className="img-fluid"></img>
          </div>
          <div className="text-center">
            <h3>Hamswriter is busy reading ... Please wait ...</h3>
          </div>
          </div><br /></div>:<div></div>}
        {
        showResults?
        <>
          <br />
          {/*
          <div className="col-12">
            <h5>Score: {result.overall_score} </h5>
            <div><br /></div>
          </div>
        */}
          <div className="col-12">
            <p><strong><em>Hamswriter is your most friendly and helpful writing buddy. It can give your suggestions and ideas but can never be as creative as you are. So don't let it dictate your writing. Use your imagination. Write the unique you!</em></strong></p>
          </div>
          <div className="col-12">
            <h5>What you did well</h5>
            <p>{result.did_well}</p>
            <br />
            <h5>What you can improve</h5>
            <p>{result.can_improve}</p>
            <br />
          </div>
          
          <div>
            <h5>Words or Phrases you can improve</h5>
            {result.expressions_to_improve.map(i => {
              return (
                <>
                <hr />
                <div className="row">
                <div className="col-3"><em>{i[0]}</em></div>
                <div className="col-6"><p>{i[1]}</p></div>
                <div className="col-3"><strong>{i[2]}</strong></div>
                </div>
                </>
              );
            })}
            <br/>
          </div>
          <div>
            <h5>Sentences you can improve</h5>
            {result.sentences_to_improve.map(i => {
              return (<>
                <hr />
                <div className="row">
                <div className="col-3"><em>{i[0]}</em></div>
                <div className="col-6"><p>{i[1]}</p></div>
                <div className="col-3"><strong>{i[2]}</strong></div>
                </div>
                </>
              );
            })}
            <br/>
          </div>
          <div>
            <h5>Grammar Improvements</h5>
            {result.grammar_mistakes.map(i => {
              return (<>
                <hr />
                <div className="row">
                <div className="col-3"><em>{i[0]}</em></div>
                <div className="col-6"><p>{i[1]}</p></div>
                <div className="col-3"><strong>{i[2]}</strong></div>
                </div>
                </>
              );
            })}
            <br/>
          </div>


{/*

          <div>
            <br />
            <h5>An Edit Example: Original vs Edited</h5>
            <hr />
            <div dangerouslySetInnerHTML={{ __html: result.original_and_edited_compared }}></div>
          </div>
          
          */}


          </>:<div></div>
        }
      </>
  
    );

}

export default Grader;
//import logo from './logo.svg';
import './App.css';
import {useState, useEffect} from 'react';
import Grader from './components/Grader';
//import Home from './components/Home';
import Report from './components/Report';
import Contact from './components/Contact';
import Hamstory from './components/Hamstory';
import Hamsfeeder from './components/Hamsfeeder';
import Hamspeaker from './components/Hamspeaker';
import Registration from './components/Registration';
import { GoogleLogin } from '@react-oauth/google';
import jwt_decode from 'jwt-decode';
//import masterLogo from './masterLogo.jpg';
import created_with_love from './created_with_love.png';
import help from './help.png';
import writing from './writing.png';
import { Routes, Route, Link, useParams, useNavigate } from "react-router-dom";
import PhotoCarousel from './components/PhotoCarousel';
import c01 from './components/c01.jpg';
import c02 from './components/c02.jpg';
import c03 from './components/c03.jpg';
import c04 from './components/c04.jpg';
import c05 from './components/c05.jpg';
import c06 from './components/c06.jpg';
import c07 from './components/c07.jpg';
import c08 from './components/c08.jpg';
import c09 from './components/c09.jpg';
import Hamsvocab from './components/Hamsvocab';


function App() {

  const carouselImgs = [c01, c02, c03, c04, c05, c06, c07, c08, c09];

  const [loggedIn, setLoggedIn] = useState(false);
  const [userInfo, setUserInfo] = useState(null);

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const [toRegistration, setToRegistration] = useState(false);

  const [loginStatus, setLoginStatus] = useState("");



  const handleLogin = async (event) => {
    event.preventDefault();

    const response = await fetch('/api/login', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ username, password })
    });

    const data = await response.json();
    if (data.success) {
      setLoggedIn(true);
      setUserInfo({email: username})
      localStorage.setItem('login', true);
      localStorage.setItem('email', username);
      navigate('/');
    } else {
      setLoginStatus('Login failed!');
    }
  };

  useEffect(() => {
    const logged = localStorage.getItem('login')=='true'?true:false;
    const email = localStorage.getItem('email');
    const info = {
      email: email,
    };
    setLoggedIn(logged);
    setUserInfo(info);
    console.log(info);
    console.log(logged);

  }, []);

  function loginSuccess(credentialResponse) {
    const info = jwt_decode(credentialResponse.credential);
    console.log(info.email);
    setLoggedIn(true);
    setUserInfo({
      email: info.email,
    });
    localStorage.setItem('login', true);
    localStorage.setItem('email', info.email);

    fetch('/api/register', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ username: info.email, password: Math.random().toString() })
    });

}

  function loginError() {
    console.log('Login Failed');
    setLoggedIn(false);
  }

  function logOut(e) {
    setLoggedIn(false);
    setUserInfo(null);
    localStorage.setItem('login', null);
    localStorage.setItem('email', null);
  }

  return (
    <div className="container">
    {loggedIn?  (
      <>
<nav className="navbar navbar-expand-lg navbar-light">
    <a className="navbar-brand" href="/">
      <img src={writing} alt="Brand Logo" height="40px"/>
      <span>{userInfo.email}</span>
    </a>
    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
      <span className="navbar-toggler-icon"></span>
    </button>
    <div className="collapse navbar-collapse" id="navbarNav">
      <ul className="navbar-nav ms-auto">
        <li className="nav-item">
          <a className="nav-link" href="/">Home</a>
        </li>
        <li className="nav-item">
          <a className="nav-link" href="/summary">Hamstory</a>
        </li>
        <li className="nav-item">
          <a className="nav-link" href="/speak">Hamspeaker</a>
        </li>
        <li className="nav-item">
          <a className="nav-link" href="/vocabulary">Hamsvocab</a>
        </li>
        <li className="nav-item">
          <a className="nav-link" href="/feed">Hamsfeeder</a>
        </li>
        <li className="nav-item">
          <a className="nav-link" href="/contact">Contact</a>
        </li>
      </ul>
      <button className="btn btn-outline-secondary" type="button" onClick={logOut}>Logout</button>
    </div>
  </nav>


              <Routes>
                  <Route index element={<Grader info={userInfo}/>} />
                  <Route path="/summary" element={<Hamstory info={userInfo} />} />
                  <Route path="/speak" element={<Hamspeaker info={userInfo} />} />
                  <Route path='/vocabulary' element={<Hamsvocab info={userInfo} />} />
                  <Route path="/feed" element={<Hamsfeeder info={userInfo} />} />
                  <Route path='/contact' element={<Contact info={userInfo} />} />
              </Routes>
    </>
    ) :  ((
      <div className="container  h-100">
        <div className="row" style={{"margin-left": "20px", "margin-top": "30px", "margin-bottom": "25px"}}>
              <h1><strong>Hamswriter</strong></h1>
              <h2>The Sure Way to Improve Writing</h2>
            </div>
      <div className="row justify-content-left align-items-center">
      <div className="col-1" />
          <div className="col-sm-12 col-md-4 align-items-center justify-content-center">
            {/*
            <div className="col-sm-11 col-md-9 col-bg-7 d-flex align-items-center justify-content-center">
              <img src={writing} alt="Logo" className="img-fluid" />
            </div>
    */}
            <div className="col-12 align-items-center justify-content-center">
              <div className="carousel-container" style={{"width": "400px"}}>
                <h4>By our young authors </h4>
                <PhotoCarousel images={carouselImgs} />
              </div>
              </div>
          </div>
          <div className="col-sm-12 col-md-5">
          <div className="col-12 text-left" style={{"margin-left": "20px", "margin-top": "15px"}}>

            <div className="row">
              <div className="row gy-3 gy-md-4 overflow-hidden">
              <form onSubmit={handleLogin}>
                <p>{loginStatus}</p>
              <div className="col-12">
                <label for="email" className="form-label">Email <span className="text-danger">*</span></label>
                <input type="email" className="form-control" name="email" id="email" placeholder="name@example.com" value={username} onChange={e => setUsername(e.target.value)} required />
              </div>
              <div className="col-12"  style={{"margin-top": "15px"}}>
                <label for="password" className="form-label">Password <span className="text-danger">*</span></label>
                <input type="password" className="form-control" name="password" id="password" value={password} onChange={e => setPassword(e.target.value)} required />
              </div>
              <div className="col-12">
                <div className="d-grid" style={{"margin-top": "15px"}}>
                  <button className="btn btn-lg btn-primary" type="submit">Log in now</button>
                </div>
              </div>
              </form>
            </div>
            </div>

            <div className="row">
            <div className="col-12">
              <hr className="mt-5 mb-4 border-secondary-subtle" />
              <div className="d-flex gap-2 gap-md-4 flex-column flex-md-row justify-content-md-end">
              <button className="btn btn-md" onClick={e => setToRegistration(!toRegistration)}>Create new account</button>
              {/*<a href="/registration" className="link-secondary text-decoration-none">Create new account</a>*/}
              </div>
            </div>
            <div className="col-12">
              {toRegistration? <Registration />: <></>}
            </div>
          </div>

            <div className="row" style={{"margin-top": "15px"}}>
              <br />
              <h5>Or sign in with </h5>
              <div className="col-sm-6 col-md-4 d-flex justify-content-center align-items-center">
                <br />
                  <GoogleLogin auto_select onSuccess={loginSuccess} onError={ loginError }>
                  </GoogleLogin>
              </div>
            </div>


              <div><br/><p>We may send you emails about your progress or ask for feedback on the service.</p></div>
              <div><p>We respect your privacy. We only collect your email, which is used to sign you in and keep track of your progress. No other personal data is collected or stored. (<a href="https://docs.google.com/document/d/1y-R-2Lp3-gynf0mLj4uDZF-gZFIWN9DxOM_h8ibk6sA">Privacy Policy</a>)</p></div>
              <div className="col-2"></div>
          </div>

          </div>




      </div>
      <div className="row vh-100 d-flex justify-content-center align-items-center">
        <div className="col-sm-12 col-md-6 text-center">
          <h1><strong>Built by Parents and Kids</strong></h1>
          <h1><strong>together with AI, 愛, Love</strong></h1>
          <p>Free. No ads. Just a simple AI tool to help young students improve writing. Type or upload your writing, and Hamswriter will provide you with feedback.</p>
        </div>
        <div className="col-sm-12 col-md-4 align-items-center justify-content-center" style={{"margin-left": "15px"}}>
            <div className="col-12 d-flex align-items-center justify-content-center">
              <img src={created_with_love} alt="created with ai" className="img-fluid" />
            </div>
          </div>
      </div>
      <div className="row vh-100 d-flex justify-content-center align-items-center">
        <div className="col-2 col-sm-12" />
        <div className="col-sm-12 col-md-4 align-items-center justify-content-center">
          <div className="col-sm-11 col-md-9 col-bg-7 d-flex align-items-center justify-content-center">
              <img src={help} alt="help" className="img-fluid" />
          </div>
        </div>
        <div className="col-sm-12 col-md-6 text-left">
          <h1><strong>Let's improve this together!</strong></h1>
          <p style={{"line-height": "160%"}}>Hi there! My name is Ke, and I live in California with my lovely family. We created Hamswriter together because we believe that everyone can be a great writer. We hope that Hamswriter can help you or your children practice writing skills and improve your writing abilities.</p>
          <p style={{"line-height": "160%"}}>We're not professional coders, and Hamswriter is still in the early stages, so please excuse any bugs or errors you might encounter. We'd love your feedback, suggestions, and help to make Hamswriter even better! If you have any thoughts, please drop us a line at kewang55@gmail.com.</p>
        </div>
      </div>
  </div>
      ))}
      </div>
  );
}

export default App;

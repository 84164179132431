import {useState} from 'react';

function Contact({info}){

    const [name, setName] = useState('');
    const [email, setEmail] = useState(info.email);
    const [message, setMessage] = useState('');
    const [messageSent, setMessageSent] = useState(false);


    function handleName(e){
        setName(e.target.value);
    }

    function handleEmail(e){
        setEmail(e.target.value);
    }

    function handleMessage(e){
        setMessage(e.target.value);
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        const data = {
            name: name,
            email: email,
            message: message,
        };
        console.log(data);
        fetch('/contact', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
          }).then(response => {setMessageSent(true); console.log(response)});
        
    };

    return (
        <div className="col-12">
            {messageSent? (
                <div className="alert alert-success" role="alert">
                    Your message has been sent. Thank you for contacting us.
                </div>
            ) : null}
            <h1>Help us make Hamswriter awesome!</h1>
            <h5>We'd love to hear your feedback on how we can improve Hamswriter. If you have any questions, comments, or suggestions, please don't hesitate to contact us.</h5>
            <form id="contactForm">
                <div class="mb-3">
                    <label for="name" className="form-label">Your Name:</label>
                    <input type="text" className="form-control" id="name" name="name" required value={name} onChange={handleName}/>
                </div>
                <div class="mb-3">
                    <label for="email" className="form-label">Your Email:</label>
                    <input type="email" className="form-control" id="email" name="email" required value={email} onChange={handleEmail}/>
                </div>
                <div class="mb-3">
                    <label for="message" className="form-label">Message:</label>
                    <textarea className="form-control" id="message" name="message" rows="5" required value={message} onChange={handleMessage}></textarea>
                </div>
                <button type="submit" className="btn btn-primary" onClick={handleSubmit}>Send</button>
            </form>
        </div>
    );
}

export default Contact;